import {
  GetAllCategoryKnowledgeDocument,
  GetAllInsightsByMagazineIdDocument,
  GetAllMagazineDocument,
  GetCourseByCategoryDocument,
  GetExchangeDocument,
  GetNewsCategoryHomePageDocument,
  Order_By,
  useGetAllMagazineQuery,
} from '@/generated/graphql';
import { addApolloState, initializeApollo } from '@/lib/apolloClient';
import { GetStaticProps } from 'next';
import React from 'react';
import {
  AllCategoryPlaylistParams,
  AllCategorySeriesParams,
  ExchangeExpertHomepageParams,
  ExchangeUserHomepageParams,
  MagazineHomepageParams,
  NewsHomepageParams,
  OtherInsightHomepageParams,
} from '@/constants/defaultParams';
import {
  AuthorHomepageCard,
  CourseHomepageCard,
  ExchangeSection,
  InsightsHomepageSection,
  KnowledgePlaylistSection,
  KnowledgeSeriesSection,
  NewsSection,
  OtherInsightSection,
} from '@/components/Home';
import { SEOconfig } from 'next-seo.config';
import { homePage } from '@/services/mock/seo';

export default function Home() {
  const { data } = useGetAllMagazineQuery({
    variables: MagazineHomepageParams,
  });

  const { thumbnail } = data?.magazine?.[0] || {};

  return (
    <>
      <SEOconfig
        description={homePage.desc}
        title={homePage.title}
        image={thumbnail || ''}
      />
      <InsightsHomepageSection />
      <div className="hidden md:block lg:hidden">
        <OtherInsightSection />
      </div>
      <KnowledgePlaylistSection />
      <CourseHomepageCard />
      <AuthorHomepageCard />
      <KnowledgeSeriesSection />
      <ExchangeSection />
      <NewsSection />
      <div className="block md:hidden lg:block">
        <OtherInsightSection />
      </div>
    </>
  );
}

export const getStaticProps: GetStaticProps = async () => {
  const apolloClient = initializeApollo();

  const { data: dataPlaylistCategory } = await apolloClient.query({
    query: GetAllCategoryKnowledgeDocument,
    variables: AllCategoryPlaylistParams,
  });

  const [magazine] = await Promise.all([
    await apolloClient.query({
      query: GetAllCategoryKnowledgeDocument,
      variables: AllCategorySeriesParams,
    }),
    apolloClient.query({
      query: GetAllMagazineDocument,
      variables: MagazineHomepageParams,
    }),
    apolloClient.query({
      query: GetCourseByCategoryDocument,
      variables: {
        where: {
          category: {
            name: {
              _eq: dataPlaylistCategory?.course_category[0].name,
            },
          },
        },
        orderBy: [
          {
            order: Order_By.AscNullsLast,
          },
        ],
      },
    }),
    apolloClient.query({
      query: GetExchangeDocument,
      variables: ExchangeExpertHomepageParams,
    }),
    apolloClient.query({
      query: GetExchangeDocument,
      variables: ExchangeUserHomepageParams,
    }),
    apolloClient.query({
      query: GetNewsCategoryHomePageDocument,
      variables: NewsHomepageParams,
    }),
    apolloClient.query({
      query: GetAllMagazineDocument,
      variables: OtherInsightHomepageParams,
    }),
  ]);

  await apolloClient.query({
    query: GetAllInsightsByMagazineIdDocument,
    variables: {
      orderBy: {
        insight_order: Order_By.AscNullsLast,
      },
      where: {
        magazine_id: {
          _eq: magazine?.data?.magazine?.[0]?.id || 0,
        },
      },
    },
  });
  return addApolloState(apolloClient, {
    props: {},
    revalidate: 30,
  });
};
