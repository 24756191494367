import React from 'react';
import { NextSeo } from 'next-seo';
import { IS_DEVELOP } from '@/constants';

interface SEOconfigProps {
  title?: string;
  description?: string;
  image?: string;
  favicon?: string;
  url?: string;
  canonical?: string;
  noIndex?: boolean;
}
const SEOconfig = ({
  title,
  description,
  image,
  url,
  canonical,
  noIndex,
}: SEOconfigProps) => {
  return (
    <NextSeo
      noindex={IS_DEVELOP || noIndex}
      nofollow={IS_DEVELOP || noIndex}
      title={title}
      description={description}
      canonical={
        canonical !== '' ? canonical : `https://tapchitrading.com${url}`
      }
      // robotsProps={{
      //   nosnippet: true,
      //   notranslate: true,
      //   noimageindex: true,
      //   noarchive: true,
      //   maxSnippet: -1,
      //   maxImagePreview: 'none',
      //   maxVideoPreview: -1,
      // }}
      openGraph={{
        title,
        description,
        type: 'website',
        locale: 'vi',
        // url: ``,
        siteName: 'Tapchitrading',
        images: [
          {
            url: image || '',
            height: 600,
            alt: image,
          },
        ],
      }}
      additionalLinkTags={[
        {
          rel: 'shortcut icon',
          href: '/favicon.ico',
        },
      ]}
    />
  );
};

export { SEOconfig };
