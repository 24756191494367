export const homePage = {
  title: 'Tạp Chí Trading | Chuyên trang thông tin, kiến thức tài chính',
  desc: 'Tạp Chí Trading - Chuyên trang thông tin, kiến thức đầu tư tài chính hàng đầu dành cho các nhà đầu tư Việt.',
};
export const aboutPage = {
  title: 'Về Chúng Tôi | Tạp Chí Trading',
  desc: 'Tạp Chí Trading chia sẻ đến quý nhà đầu tư những thông tin, kiến thức tài chính quan trọng trên toàn thế giới, được cập nhật liên tục và đều đặn.',
};
export const newsPage = {
  title: 'Cập nhật tin tức tài chính mới nhất | Tạp Chí Trading',
  desc: 'Cập nhật ngay những tin tức tài chính về chứng khoán, ngoại hối, chỉ số, vàng... cùng các sự kiện nổi bật khác trên toàn thế giới.',
};
export const blogPage = {
  title: 'Blog | Tạp Chí Trading',
  desc: 'Nơi chia sẻ những thông tin hữu ích liên quan đến tài chính như đầu tư chứng khoán, ngoại hối, chỉ số, hàng hóa...',
};
export const knowledgePage = {
  title: 'Kho video kiến thức tài chính | Tạp Chí Trading',
  desc: 'Truy cập kho video kiến thức tài chính Tạp Chí Trading để khám phá các video hướng dẫn, phân tích và giải thích các vấn đề tài chính một cách dễ hiểu nhất.',
};
export const exchangePage = {
  title: 'Tra cứu - Đánh giá sàn giao dịch uy tín tại Việt Nam',
  desc: 'Nơi tổng hợp các sàn giao dịch cùng đánh giá sàn từ chuyên gia và nhà đầu tư để có cái nhìn tổng quan toàn diện về các sàn giao dịch tại Việt Nam.',
};
export const insightPage = {
  title: 'Danh sách tạp chí tài chính | Tạp Chí Trading',
  desc: 'Các số tạp chí tài chính tổng những thông tin nổi bật về thị trường đầu tư như thương vụ "hot", kinh nghiệm đầu tư, nhận định thị trường từ chuyên gia...',
};

export const exchangeListing = {
  title: 'Danh sách tạp chí tài chính | Tạp Chí Trading',
  desc: '',
};

export const notFoundPage = {
  title: 'Page not found | Tạp Chí Trading',
  desc: '',
};
